<template>
    <div>
        <div class="static-head">
            <div class="message-container">
                <h1>Information</h1>
            </div>
        </div>
        <div class="static site__centered">
            
            <div class="static-body">
                <div class="static-header">
                    <h3>Boys Camping</h3>
                </div>
                <div class="static-text-container row">
                    <div class="col-6 col-sm-12">
                        <span>
                            Sed eu risus in lectus fermentum laoreet et sit amet sem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In ac luctus purus, sed pretium ligula. Aliquam blandit sapien id urna pulvinar, eu bibendum nisl facilisis. Vivamus ullamcorper elit porttitor arcu posuere varius. Sed eu dignissim augue, sit amet ullamcorper orci. Sed ac quam id nibh euismod ultrices nec cursus urna. Donec at libero nunc. Proin malesuada convallis nibh at lacinia. Duis mollis, urna et rhoncus gravida, sapien arcu tincidunt velit
                        </span>
                    </div>
                    <div class="col-6 col-sm-12">
                        <span>
                            Sed eu risus in lectus fermentum laoreet et sit amet sem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In ac luctus purus, sed pretium ligula. Aliquam blandit sapien id urna pulvinar, eu bibendum nisl facilisis. Vivamus ullamcorper elit porttitor arcu posuere varius. Sed eu dignissim augue, sit amet ullamcorper orci. Sed ac quam id nibh euismod ultrices nec cursus urna. Donec at libero nunc. Proin malesuada convallis nibh at lacinia. Duis mollis, urna et rhoncus gravida, sapien arcu tincidunt velit
                        </span>
                    </div>
                </div>

                <div class="static-text-box row">
                    <div class="col-6 col-sm-12">
                        <div class="box">
                            <h3>What you will learn</h3>
                            <ul type="disc">
                                <li>Sed eu risus in lectus fermentum laoreet et sit amet sem. Orci varius natoque</li>
                                <li>In consectetur quam non est convallis commodo in eget purus. Sed interdum,</li>
                                <li>Donec ac eros pretium, fringilla augue a, convallis lectus. Sed faucibus elit vel
                                </li>
                                <li>Nullam in rhoncus risus, sit amet auctor nibh. Donec consequat posuere velit, et
                                    interdum quam interdum quis. Curabitur facilisis erat ac
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6 col-sm-12">
                        <div class="box">
                            <h3>Instructions</h3>
                            <ul type="disc">
                                <li>Sed eu risus in lectus fermentum laoreet et sit amet sem. Orci varius natoque</li>
                                <li>In consectetur quam non est convallis commodo in eget purus. Sed interdum,</li>
                                <li>Donec ac eros pretium, fringilla augue a, convallis lectus. Sed faucibus elit vel
                                </li>
                                <li>Nullam in rhoncus risus, sit amet auctor nibh. Donec consequat posuere velit, et
                                    interdum quam interdum quis. Curabitur facilisis erat ac
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Information"
    }
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/colors/colors';

.message-container {
    position: absolute;
    font-size: 66px;
    font-weight: 700;
    color: white;
    text-align: center;
    width: 100%;
}

.message-container p, message-container span {
    font-weight: 400;
}

.message-container p {
    color: #0D1333;
    font-size: 18px;
    margin-bottom: 20px;
}

.message-container span {
    color: #8F95B2;
    font-size: 14px;
}

.message-container a {
    width: 100%;
    margin-top: 30px;
}

.message-container a span {
    color: #ffffff;
}

.message-container a:hover span {
    color: $primary;
}

.static-body {
    margin: 30px 0;
}

.static-header {
    display: flex;
}

.static-header h3 {
    font-weight: 900;
    font-size: 36px;
    color: #000000;
}

.static-header span {
    /*margin-left: 70px;*/
    padding-left: 60px;
    font-weight: 400;
    font-size: 20px;
    /*line-height: 22px;*/
}

.static-text-container {
    margin: 25px 0;
}

.static-text-container span {
    font-size: 20px;
    font-weight: 200;
    line-height: 30px;
}

.static-text-box .box {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E9EAEF;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 44px;
    min-height: 280px;
}

.static-text-box .box h3 {

    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: $secondary;
    margin-bottom: 20px;
}

.static-text-box .box ul li {
    list-style-type: disc;
    font-size: 16px;
    line-height: 30px;
}

.static-text-card {
    margin: 35px 0;
}

.card {
    width: 100%;
    border-radius: 15px;
    min-height: 280px;
    border-radius: 15px;
    padding: 31px;
    color: #ffffff;
}

.card.blue {
    background: #E8E6E6;
}

.card.blue .blue-col {
    font-size: 16px;
    line-height: 21px;
    margin: 10px 0;
}

.card.blue .blue-col,
.card.logo-card .logo-col {
    margin-left: 10px;
}

.card.blue .blue-col:before,
.card.logo-card .logo-col:before {
    content: "";
    display: block;
    /*background-image: url('/assets/images/icons/icon-mail.svg');*/
    /*background-size: cover;*/

    background: $secondary;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 18px;
    left: 0;
}

.card.logo-card .logo-col:before {
    top: 10px;
}

.card.logo-card {
    background: #EDA600;
    display: flex;
    align-items: flex-start !important;
    flex-direction: column !important;
    /*background-image: url("/assets/picture/Rectangle 434.png");*/
    background-size: cover;
}

.card.logo-card h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 32px;
    margin-bottom: auto !important;
}

.card.logo-card .logo-card-container {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 24px;
    width: 100%;
    color: $secondary;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-6, .col-4 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}
@media screen and (max-width: 767px) {
    .static-text-container span {
        font-size: 16px;
    }
    .static-text-box .box {
        margin-bottom: 30px;
    }
    .col-sm-12 {
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
</style>